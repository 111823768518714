import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, ReactNode, useState } from 'react'
import {
  ModalEditVehicle,
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyRestricted,
  MoovyTable
} from '../../../components'
import {
  RightDTO,
  RightHolderResponseDTO,
  RightHolderVehicleDTO
} from '../../../apis/rightHolders/dto'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { UseQueryResult, useQueryClient } from '@tanstack/react-query'

import { BackendDatetime } from '../../../utils/datetimeUtils'
import { DateTime } from 'luxon'
import { QUERY_RIGHT_HOLDER_GET_BY_REF } from '../../../apis/moovyQueryKeys'
import useLocale from '../../../hooks/useLocale'

export type RightHolderVehicle = {
  licensePlate: string
  costCenter?: string
  project?: string
  rightId: string
}

const TableVehicles = (props: {
  vehicleRightHolders: RightHolderVehicleDTO[]
  rightHolderId: string
  queryRightHolder: UseQueryResult<RightHolderResponseDTO, any>
  allowToEndRight: boolean
  refreshOffering: () => void
}) => {
  const queryClient = useQueryClient()
  const [showModalEditVehicle, setShowModalEditVehicle] = useState<
    RightHolderVehicle | undefined
  >(undefined)
  const intl = useIntl()
  const { locale } = useLocale()

  const RenderVehicleData = ({
    vehicle
  }: {
    vehicle: RightHolderVehicleDTO
  }) => {
    return (
      <>
        {vehicle.rights.map((right) => (
          <TableRow key={right.id}>
            <TableCell>
              <MoovyDateTime utcDateTime={vehicle.createdAt} />
            </TableCell>
            <TableCell>{vehicle.licensePlate}</TableCell>
            <RenderRightData
              licensePlate={vehicle.licensePlate}
              right={right}
            />
          </TableRow>
        ))}
      </>
    )
  }

  const RenderRightData = ({
    licensePlate,
    right
  }: {
    licensePlate: string
    right: RightDTO
  }) => {
    return (
      <>
        <TableCell>{right.offering?.name}</TableCell>
        <TableCell>{right.place?.name}</TableCell>
        <TableCell>
          <MoovyDateTime utcDateTime={right.validityStart} />
        </TableCell>
        <TableCell>
          <MoovyDateTime
            utcDateTime={right?.validityEnd}
            emptyText={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.tab.vehicles.table.item.permanent'
            })}
          />
        </TableCell>
        <RenderButton licensePlate={licensePlate} right={right} />
      </>
    )
  }

  const isEndableRight = (right: RightDTO) => {
    return (
      props.allowToEndRight &&
      right &&
      (!right.validityEnd ||
        BackendDatetime.fromUTC(right.validityEnd, locale) > DateTime.now())
    )
  }

  const RenderButton = ({
    licensePlate,
    right
  }: {
    licensePlate: string
    right: RightDTO
  }) => {
    return (
      <MoovyRestricted action="EDIT" resource="RIGHTS">
        <TableCell>
          {isEndableRight(right) && (
            <MoovyLink
              bold
              onClick={() =>
                setShowModalEditVehicle({
                  licensePlate: licensePlate,
                  costCenter: right.costCenter,
                  project: right.project,
                  rightId: right.id
                })
              }
            >
              <FormattedMessage id="common.buttons.edit" />
            </MoovyLink>
          )}
        </TableCell>
      </MoovyRestricted>
    )
  }

  const RenderGridVehicleData = ({
    vehicle
  }: {
    vehicle: RightHolderVehicleDTO
  }) => {
    return (
      <MoovyGrid
        emptyTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.empty"
        errorTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.failed"
        query={props.queryRightHolder}
      >
        {vehicle.rights.map((right) => (
          <MoovyGridCard
            key={vehicle.id}
            cardTitle={vehicle.licensePlate}
            cardSecondaryTitle={
              <MoovyDateTime utcDateTime={vehicle.createdAt} />
            }
            cardContentList={getGridRightData(right)}
            cardMenuActions={getMenuItems(vehicle, right)}
          />
        ))}
      </MoovyGrid>
    )
  }

  const getGridRightData = (right: RightDTO) => {
    const getBySpace = (first: ReactNode, second: ReactNode | string) => {
      return (
        <div>
          {first} {second}
        </div>
      )
    }

    return [
      getBySpace(
        <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.location" />,
        right?.place?.name
      ),
      getBySpace(
        <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.right" />,
        <>{right?.offering?.name}</>
      ),
      getBySpace(
        <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.startRight" />,
        <MoovyDateTime utcDateTime={right?.validityStart} />
      ),
      getBySpace(
        <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.endRight" />,
        <MoovyDateTime
          utcDateTime={right?.validityEnd}
          emptyText={intl.formatMessage({
            id: 'page.rightHolders.rightHolder.tab.vehicles.table.item.permanent'
          })}
        />
      )
    ]
  }

  const getMenuItems = (vehicle: RightHolderVehicleDTO, right: RightDTO) => {
    return isEndableRight(right)
      ? [
          {
            itemText: intl.formatMessage({
              id: 'common.buttons.edit'
            }),
            onClick: () =>
              setShowModalEditVehicle({
                licensePlate: vehicle.licensePlate,
                rightId: right.id
              })
          }
        ]
      : undefined
  }

  const closeModalsAndRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_RIGHT_HOLDER_GET_BY_REF, props.rightHolderId]
    })
    setShowModalEditVehicle(undefined)
    props.refreshOffering()
  }

  return (
    <>
      <MoovyGrid
        emptyTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.empty"
        errorTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.failed"
        query={props.queryRightHolder}
      >
        {props.vehicleRightHolders.map((row) => (
          <Fragment key={row.id}>
            <RenderGridVehicleData vehicle={row} />
          </Fragment>
        ))}
      </MoovyGrid>
      <MoovyTable
        items={props.vehicleRightHolders}
        emptyTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.empty"
        errorTextId="page.rightHolders.rightHolder.tab.vehicles.table.action.failed"
        query={props.queryRightHolder}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.header.createdAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="common.text.licensePlate" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.header.right" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.header.place" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.header.validFrom" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.rightHolders.rightHolder.tab.vehicles.table.header.validUntil" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody data-testid="places-table-body">
          {props.vehicleRightHolders.map((row) => (
            <Fragment key={row.id}>
              <RenderVehicleData vehicle={row} />
            </Fragment>
          ))}
        </TableBody>
      </MoovyTable>
      <ModalEditVehicle
        open={!!showModalEditVehicle}
        rightId={showModalEditVehicle?.rightId || ''}
        licensePlate={showModalEditVehicle?.licensePlate || ''}
        costCenter={showModalEditVehicle?.costCenter}
        project={showModalEditVehicle?.project}
        onTerminated={() => closeModalsAndRefresh()}
        onSubmit={() => {
          closeModalsAndRefresh()
        }}
        onCancel={() => setShowModalEditVehicle(undefined)}
      />
    </>
  )
}

export default TableVehicles
