import FormIndividualRightHolder, {
  DefaultRightHolderValues
} from '../formIndividualRightHolder'
import {
  RightHolderCreateIndividualDTO,
  RightHolderCreateRightHolderDTO,
  RightHolderCreateVehicleDTO
} from '../../../../../apis/rightHolders/dto'

import { BackendDatetime } from '../../../../../utils/datetimeUtils'
import { OfferingNameIdDTO } from '../../../../../apis/offering/dto'

export type StepAddRightHolderProps = {
  formReference: string
  customerId: string
  individualRightHolderId?: string
  catalogueId: string
  offering: OfferingNameIdDTO | undefined
  defaultValues?: DefaultRightHolderValues
  onSubmit: (values: RightHolderCreateRightHolderDTO) => void
}

const StepAddRightHolder = (props: StepAddRightHolderProps) => {
  const createRightHolder = (values: DefaultRightHolderValues) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      licensePlate,
      catalogueId,
      project,
      costCenter
    } = values

    if (props.individualRightHolderId) {
      const dto: RightHolderCreateVehicleDTO = {
        type: 'vehicle',
        customerId: props.customerId,
        individualRightHolderId: props.individualRightHolderId,
        licensePlate,
        catalogueId,
        grantRights: [
          {
            rightHolderType: 'vehicle',
            validityStart: BackendDatetime.nowToUTC(),
            validityEnd: undefined,
            active: true,
            costCenter,
            project
          }
        ]
      }
      props.onSubmit(dto)
    } else {
      const dto: RightHolderCreateIndividualDTO = {
        type: 'individual',
        customerId: props.customerId,
        firstName,
        lastName,
        email,
        phoneNumber,
        catalogueId,
        vehicles: [
          {
            licensePlate,
            grantRights: [
              {
                rightHolderType: 'vehicle',
                validityStart: BackendDatetime.nowToUTC(),
                validityEnd: undefined,
                active: true,
                costCenter,
                project
              }
            ]
          }
        ]
      }
      props.onSubmit(dto)
    }
  }

  return (
    <FormIndividualRightHolder
      formReference={props.formReference}
      disableCustomerData={!!props.individualRightHolderId}
      offering={props.offering}
      catalogueId={props.catalogueId || ''}
      onSubmit={createRightHolder}
      defaultValues={props.defaultValues}
    />
  )
}

export default StepAddRightHolder
