import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_OFFERINGS_GET_BY_PLACE,
  QUERY_PLACE_GET_ALL,
  QUERY_PLACE_GET_BY_REF
} from '../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyMutationError,
  MoovyQueryHandler,
  MoovyScrollToTop,
  MoovyTab
} from '../../components'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { DefaultPlaceValues } from './formPlace'
import { Grid } from '@mui/material'
import ModalEditPlace from '../places/place/modalEditPlace'
import { MoovyToast } from '../../utils/moovyToast'
import { OfferingResponseDTO } from '../../apis/offering/dto'
import OfferingsTab from './place/offeringsTab'
import PlaceInformation from './place/placeInformation'
import { PlacesAPI } from '../../apis/places/placesAPI'
import { SS_PLACES_TAB_OFFERINGS } from '../../redux/globalKeys'
import SituationOverview from './place/situationOverview'
import useCustomerData from '../../hooks/useCustomerData'
import { useParams } from 'react-router-dom'

const Place = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const { placeRef } = useParams()
  const { place, setPlace } = useCustomerData()
  const [showPlaceModal, setShowPlaceModal] = useState(false)

  const queryPlace = useQuery({
    queryKey: [QUERY_PLACE_GET_BY_REF, placeRef],
    queryFn: () => PlacesAPI.getPlaceByRef(placeRef || ''),
    enabled: !!placeRef,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const queryOfferings = useQuery({
    queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, placeRef],
    queryFn: () => PlacesAPI.getOfferingsByPlace(placeRef || ''),
    enabled: !!placeRef,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const defaultPlaceData: DefaultPlaceValues = {
    name: queryPlace.data?.name || '',
    streetAddress: queryPlace.data?.streetAddress || '',
    postalCode: queryPlace.data?.postalCode || '',
    city: queryPlace.data?.city || '',
    capacity: queryPlace.data?.capacity?.toString() || '',
    customerId: queryPlace.data?.customer?.id || ''
  }

  const isParkingEnforcement = !!queryPlace.data?.parkingEnforcementZoneId

  const normalOfferings: OfferingResponseDTO[] = queryOfferings.data
    ? queryOfferings.data.filter((item) => item.type !== 'WHITELIST')
    : []

  const cataloguesTotal: number = normalOfferings
    ?.map((item) => item.summary.cataloguesTotalAmount)
    .reduce((accumulator, current) => accumulator + current, 0)

  const offeringsTotal: number = normalOfferings.reduce(
    (accumulator, offering) => accumulator + offering.amount,
    0
  )

  return (
    <MoovyQueryHandler query={queryPlace} rootPageIfNotFoundError>
      <MoovyScrollToTop />
      <h1>{place?.name}</h1>
      <Grid container spacing={2} direction="row">
        <Grid item md={6} xs={12}>
          {queryPlace.error || queryOfferings.error ? (
            <MoovyMutationError
              errorTitleKey={'common.actions.get.error'}
              query={queryPlace.isError ? queryPlace : queryOfferings}
            />
          ) : (
            <PlaceInformation
              loading={queryPlace.isPending || queryOfferings.isPending}
              place={queryPlace.data}
              offeringAmount={offeringsTotal}
              onEdit={() => setShowPlaceModal(true)}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <MoovyMutationError
            errorTitleKey={'common.actions.get.error'}
            query={queryPlace}
          />
          <SituationOverview
            parkingEnforcement={isParkingEnforcement}
            isLoading={queryPlace.isPending}
            capacity={queryPlace.data?.capacity}
            cataloguesTotal={cataloguesTotal}
          />
        </Grid>
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerContent>
              <MoovyTab
                tabKey={SS_PLACES_TAB_OFFERINGS}
                tabHeaders={[
                  intl.formatMessage({
                    id: 'page.place.offeringsTab.header'
                  })
                ]}
                tabPanels={[
                  <OfferingsTab
                    queryOfferings={queryOfferings}
                    placeId={placeRef || ''}
                  />
                ]}
              />
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
        <ModalEditPlace
          open={showPlaceModal}
          editablePlaceRef={placeRef || ''}
          defaultValues={defaultPlaceData}
          onSubmit={(response) => {
            MoovyToast.showToast(
              <FormattedMessage
                id="page.place.actions.updated"
                values={{ name: response?.name }}
              />
            )
            setShowPlaceModal(false)
            response && response.id === place.id && setPlace(response)
            queryClient.invalidateQueries({
              queryKey: [QUERY_PLACE_GET_BY_REF, placeRef]
            })
            queryClient.invalidateQueries({
              queryKey: [QUERY_PLACE_GET_ALL]
            })
          }}
          onCancel={() => setShowPlaceModal(false)}
        />
      </Grid>
    </MoovyQueryHandler>
  )
}

export default Place
