import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme
} from '@mui/material'
import { MoovyButton, MoovyMutationError } from '..'

import { AxiosResponse } from 'axios'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { UseMutationResult } from '@tanstack/react-query'

export type MoovyDialogConfirmationProps = {
  open: boolean
  header: string
  content: string | React.ReactNode
  lang: {
    confirmButton: string
    error: string
  }
  onCancel: () => void
  onConfirm: () => void
  mutation?: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>
  disableSubmit?: boolean
}

const MoovyDialogConfirmation = (props: MoovyDialogConfirmationProps) => {
  const { open, header, content, lang, onCancel, onConfirm } = props
  const theme = useTheme()

  const resetView = () => {
    props.mutation?.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetView()
        onCancel()
      }}
    >
      <DialogTitle sx={{ pr: 7 }}>{header}</DialogTitle>
      <IconButton
        onClick={props.onCancel}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <DialogContent dividers>
        <MoovyMutationError
          mutation={props.mutation}
          errorTitleKey={lang.error}
        />
        <div style={{ padding: '5px' }}>{content}</div>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <MoovyButton
          onClick={() => {
            resetView()
            onCancel()
          }}
          variant="text"
        >
          <FormattedMessage id="common.buttons.cancel" />
        </MoovyButton>
        <MoovyButton
          disabled={props.mutation?.isPending || props.disableSubmit}
          mutation={props.mutation}
          onClick={() => {
            onConfirm()
          }}
        >
          <FormattedMessage id={lang.confirmButton} />
        </MoovyButton>
      </DialogActions>
    </Dialog>
  )
}

export default MoovyDialogConfirmation
