import * as yup from 'yup'

import React, { useState } from 'react'

import { Formik } from 'formik'
import { MoovyForm } from '..'
import { isValidLicensePlate } from '../../utils/validator'
import { useIntl } from 'react-intl'

export interface EditVehicleValues {
  licensePlate?: string
}

export type FormEditVehicleProps = {
  formReference: string
  onSubmit: (licensePlate: string) => void
  licensePlate: string
  costCenter?: string
  project?: string
  showAccountingFields?: boolean
}

const FormEditVehicle = (props: FormEditVehicleProps) => {
  const intl = useIntl()

  const getFormDefaultValues = () => {
    return {
      licensePlate: props.licensePlate
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)

  const schemaValidator = () => {
    return yup.object().shape({
      licensePlate: yup
        .string()
        .trim()
        .test(
          'licensePlate',
          intl.formatMessage({
            id: 'common.form.errors.licensePlate'
          }),
          function (licensePlate?: string) {
            return isValidLicensePlate(licensePlate)
          }
        )
    })
  }

  const onSubmitFormValues = (values: { licensePlate: string }) => {
    const { licensePlate } = values

    props.onSubmit(licensePlate.trim().toLocaleUpperCase())
  }

  return (
    <Formik
      validate={() => setFormikValidateOnChange(true)}
      validationSchema={schemaValidator()}
      enableReinitialize={true}
      initialValues={getFormDefaultValues()}
      validateOnChange={formikValidateOnChange}
      onSubmit={(values) => {
        onSubmitFormValues(values)
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit} id={props.formReference}>
          <MoovyForm.FormContainer>
            <MoovyForm.FormTextField
              name="licensePlate"
              data-testid="licensePlate"
              value={values.licensePlate}
              label={intl.formatMessage({
                id: 'common.text.licensePlate'
              })}
              placeholder={intl.formatMessage({
                id: 'common.text.licensePlate'
              })}
              onChange={handleChange}
              error={!!errors.licensePlate}
              errorText={errors.licensePlate}
            />

            {props.showAccountingFields && (
              <>
                <MoovyForm.FormTextField
                  name="costCenter"
                  data-testid="costCenter"
                  disabled
                  value={props.costCenter}
                  label={intl.formatMessage({
                    id: 'common.text.costCenter'
                  })}
                />
                <MoovyForm.FormTextField
                  name="project"
                  data-testid="project"
                  value={props.project}
                  disabled
                  label={intl.formatMessage({
                    id: 'common.text.project'
                  })}
                />
              </>
            )}
          </MoovyForm.FormContainer>
        </form>
      )}
    </Formik>
  )
}

export default FormEditVehicle
