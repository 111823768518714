import {
  AllocatedRightsOffering,
  AllocatedRightsCatalogue,
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyRestricted,
  MoovySortAndFilter,
  MoovyTable,
  TimeStampItems
} from '../../components'
import {
  CatalogueResponseDTO,
  CatalogueSortType
} from '../../apis/catalogues/dto'
import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CATALOGUES_BY_OFFERING,
  QUERY_OFFERINGS_GET_BY_ID,
  QUERY_OFFERINGS_GET_BY_PLACE
} from '../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyPaginationProps,
  PagingAndSortingValues,
  convertPaginationValues,
  getPagingAndSortInitValues
} from '../../components/moovyPagination'
import React, { useState } from 'react'
import {
  SS_CATALOGUES_PAGING,
  SS_CATALOGUES_SORTING
} from '../../redux/globalKeys'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import {
  getDirection,
  getSortItems
} from '../../components/moovySortAndFilter/sortValues'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import ModalCreateCatalogue from './modalCreateCatalogue'
import ModalEditCatalogue from './modalEditCatalogue'
import { OfferingResponseDTO } from '../../apis/offering/dto'
import { appRoutes } from '../../app/permissions'
import useAuth from '../../hooks/useAuth'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useNavigate } from 'react-router-dom'

export interface CataloguesTabProps {
  offering?: OfferingResponseDTO
}

const CataloguesTab: React.FC<CataloguesTabProps> = ({ offering }) => {
  const navigate = useNavigate()
  const { isPermission } = useAuth()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [showCreateCatalogueModal, setShowCreateCatalogueModal] =
    useState(false)
  const [selectedCatalogueRef, setSelectedCatalogueRef] = useState<
    string | undefined
  >(undefined)
  const [pagingAndSortValues, setPagingAndSortValues] = useGlobalUI({
    key: SS_CATALOGUES_PAGING,
    initialValue: getPagingAndSortInitValues<CatalogueSortType>('CREATED_AT')
  })

  if (!offering) {
    return null
  }

  const queryCatalogs = useQuery({
    queryKey: [
      QUERY_CATALOGUES_BY_OFFERING,
      offering.id,
      pagingAndSortValues.page,
      pagingAndSortValues.sort
    ],
    queryFn: () => {
      const { page, limit, sort, direction } = convertPaginationValues(
        pagingAndSortValues
      ) as PagingAndSortingValues

      return CataloguesAPI.getByOffering(offering.id, {
        page,
        limit,
        sort: sort as CatalogueSortType,
        direction
      })
    },
    staleTime: DEFAULT_CACHE_TIME_MS
  })
  const catalogues = queryCatalogs.data?.data ?? []
  const pagination: MoovyPaginationProps | undefined = queryCatalogs.data
    ?.pageable
    ? {
        page: queryCatalogs.data.pageable.page,
        total: queryCatalogs.data.pageable.total,
        limit: queryCatalogs.data.pageable.limit,
        onNextPage: (page) =>
          setPagingAndSortValues({
            ...pagingAndSortValues,
            page: page.toString()
          })
      }
    : undefined

  const onClickRow = (item: CatalogueResponseDTO) => {
    navigate(`${appRoutes.CATALOGUES}/${item.id}`)
  }

  const refreshData = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_CATALOGUES_BY_OFFERING, offering.id]
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, offering.placeId]
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_OFFERINGS_GET_BY_ID, offering.placeId]
    })
  }

  const items = getSortItems(intl, 'CATALOGUES')

  return (
    <div>
      <MoovyContainer>
        <MoovyContainerSubHeader
          description={
            <>
              <FormattedMessage id="page.offering.cataloguesTab.infoText1" />
              <MoovyRestricted action="CREATE" resource="CATALOGUES">
                <div>
                  <FormattedMessage id="page.offering.cataloguesTab.infoText2" />
                </div>
              </MoovyRestricted>
              <MoovySortAndFilter
                pt={2}
                filterKey={SS_CATALOGUES_SORTING}
                items={items}
                value={pagingAndSortValues.sort}
                onChange={(value) => {
                  pagingAndSortValues.sort !== value &&
                    setPagingAndSortValues({
                      ...pagingAndSortValues,
                      sort: value,
                      direction: getDirection(value as CatalogueSortType)
                    })
                }}
              />
            </>
          }
          button={
            <MoovyRestricted action="CREATE" resource="CATALOGUES">
              <MoovyButton onClick={() => setShowCreateCatalogueModal(true)}>
                <FormattedMessage id="page.offering.cataloguesTab.createCatalogue" />
              </MoovyButton>
            </MoovyRestricted>
          }
        />
        <MoovyContainerContent>
          <MoovyGrid
            emptyTextId="page.offering.cataloguesTab.table.empty"
            errorTextId="page.offering.cataloguesTab.table.error"
            query={queryCatalogs}
            pagination={pagination}
          >
            {catalogues.map((catalog) => (
              <MoovyGridCard
                key={catalog.id}
                cardTitle={catalog.name || catalog.customer.name}
                cardSecondaryTitle={
                  <MoovyDateTime utcDateTime={catalog.createdAt} dateOnly />
                }
                cardMenuActions={
                  isPermission('EDIT', 'CATALOGUES')
                    ? [
                        {
                          itemText: intl.formatMessage({
                            id: 'page.offering.cataloguesTab.table.catalogue.edit'
                          }),
                          onClick: () => setSelectedCatalogueRef(catalog.id)
                        }
                      ]
                    : undefined
                }
                cardContentList={[
                  <FormattedMessage id="page.offering.cataloguesTab.table.customer" />,
                  <b>{catalog.customer.name}</b>,
                  <AllocatedRightsCatalogue
                    amount={catalog.amount}
                    activeRights={catalog.activeRights}
                  />
                ]}
                onCardClicked={() => onClickRow(catalog)}
              />
            ))}
          </MoovyGrid>
          <MoovyTable
            items={catalogues}
            emptyTextId="page.offering.cataloguesTab.table.empty"
            errorTextId="page.offering.cataloguesTab.table.error"
            query={queryCatalogs}
            pagination={pagination}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="page.offering.cataloguesTab.table.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.offering.cataloguesTab.table.customer" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.offering.cataloguesTab.table.overview" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.offering.cataloguesTab.table.timestamps" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {catalogues.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => onClickRow(item)}
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <MoovyLink>{item.name || ''}</MoovyLink>
                  </TableCell>
                  <TableCell>
                    <MoovyLink>{item.customer.name}</MoovyLink>
                  </TableCell>
                  <TableCell>
                    {offering.type === 'WHITELIST' ? (
                      <AllocatedRightsOffering offering={offering} />
                    ) : (
                      <AllocatedRightsCatalogue
                        amount={item.amount}
                        activeRights={item.activeRights}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <TimeStampItems
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                      dateOnly
                    />
                  </TableCell>
                  <TableCell align="right">
                    {isPermission('EDIT', 'CATALOGUES') && (
                      <MoovyLink
                        onClick={(evt) => {
                          evt.stopPropagation()
                          setSelectedCatalogueRef(item.id)
                        }}
                      >
                        <FormattedMessage id="page.offering.cataloguesTab.table.catalogue.edit" />
                      </MoovyLink>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MoovyTable>
          <ModalCreateCatalogue
            open={showCreateCatalogueModal}
            onCancel={() => setShowCreateCatalogueModal(false)}
            onSubmit={() => {
              setShowCreateCatalogueModal(false)
              refreshData()
            }}
            offering={offering}
          />
          <ModalEditCatalogue
            open={!!selectedCatalogueRef}
            onCancel={() => setSelectedCatalogueRef(undefined)}
            onSubmit={() => {
              setSelectedCatalogueRef(undefined)
              refreshData()
            }}
            catalogue={catalogues.find(
              (item) => item.id === selectedCatalogueRef
            )}
            offering={offering}
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </div>
  )
}

export default CataloguesTab
