import FormVehicleRightHolder, {
  DefaultRightHolderValues
} from '../formVehicleRightHolder'
import {
  getRightHolderName,
  RightHolderCreateRightHolderDTO,
  RightHolderCreateVehicleDTO,
  RightHolderIndividualDTO,
  RightHolderResponseDTO,
  RightHolderVehicleDTO
} from '../../../../../apis/rightHolders/dto'

import { BackendDatetime } from '../../../../../utils/datetimeUtils'
import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '../../../../../components'
import { MoovyToast } from '../../../../../utils/moovyToast'
import RightHoldersAPI from '../../../../../apis/rightHolders/rightHoldersAPI'
import { useMutation } from '@tanstack/react-query'

type SubmitModalCallback = (values: RightHolderResponseDTO) => void
type CancelModalCallback = () => void
export type ModalAddRightHolderProps = {
  customerId: string
  catalogueId: string
  open: boolean
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
  showAccountingFields?: boolean
}

const ModalAddRightHolder = (props: ModalAddRightHolderProps) => {
  const FORM_VEHICLE_RIGHT_HOLDER = 'formModalVehicleRightHolder'

  const createRightHolderMutation = useMutation({
    mutationFn: (dto: RightHolderCreateRightHolderDTO) =>
      RightHoldersAPI.createIndividual(dto),
    onSuccess: (response) => {
      const rightHolder: RightHolderResponseDTO = response.data || {}
      let name = ''

      if (rightHolder?.type === 'individual') {
        const dataIndividual = rightHolder as RightHolderIndividualDTO
        name = getRightHolderName(dataIndividual)
      } else if (rightHolder?.type === 'vehicle') {
        const dataVehicle = rightHolder as RightHolderVehicleDTO
        name = getRightHolderName(dataVehicle)
      }
      MoovyToast.showToast(
        <FormattedMessage
          id="page.catalogues.tabCatalogue.modalFindRightHolder.actions.added"
          values={{ name }}
        />
      )
      props.onSubmit(rightHolder)
    }
  })

  const cancel = () => {
    createRightHolderMutation.reset()
    props.onCancel()
  }

  const createVehicleRightHolder = (values: DefaultRightHolderValues) => {
    const { licensePlate, costCenter, project } = values

    const dto: RightHolderCreateVehicleDTO = {
      type: 'vehicle',
      customerId: props.customerId,
      licensePlate,
      catalogueId: props.catalogueId,
      grantRights: [
        {
          rightHolderType: 'vehicle',
          validityStart: BackendDatetime.nowToUTC(),
          validityEnd: undefined,
          active: true,
          costCenter: costCenter?.trim() || undefined,
          project: project?.trim() || undefined
        }
      ]
    }
    createRightHolderMutation.mutate(dto)
  }

  return (
    <MoovyDialog
      open={props.open}
      onCancel={cancel}
      formReference={FORM_VEHICLE_RIGHT_HOLDER}
      mutation={createRightHolderMutation}
      errorTitleKey="common.actions.add.error"
      modalTitle={
        <FormattedMessage id="page.catalogues.modalAddRightHolder.title" />
      }
      submitButtonContent={<FormattedMessage id="common.buttons.save" />}
    >
      <FormVehicleRightHolder
        formReference={FORM_VEHICLE_RIGHT_HOLDER}
        onSubmit={createVehicleRightHolder}
        showAccountingFields={props.showAccountingFields}
      />
    </MoovyDialog>
  )
}

export default ModalAddRightHolder
