import { CatalogueReportDTO, RightReportDTO } from './dto'

import { API_ROOT_URL } from '..'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/reports`

const getCataloguesReport = (
  customerIds: string[],
  validFrom: string,
  validUntil: string
) => {
  return client
    .get<CatalogueReportDTO>(`${BASE_URL}/catalogues`, {
      params: {
        customerIds,
        validFrom,
        validUntil
      },
      paramsSerializer: { indexes: null }
    })
    .then((response) => response.data)
}

const getRightsReport = (
  customerIds: string[],
  validFrom: string,
  validUntil: string
) => {
  return client
    .get<RightReportDTO>(`${BASE_URL}/rights`, {
      params: {
        customerIds,
        validFrom,
        validUntil
      },
      paramsSerializer: { indexes: null }
    })
    .then((response) => response.data)
}

export const ReportsAPI = {
  getCataloguesReport,
  getRightsReport
}
