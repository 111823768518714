import {
  AllocatedRightsOffering,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyQueryHandler,
  MoovyTable,
  TimeStampItems
} from '../../../components'
import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'
import { UseQueryResult, useQueryClient } from '@tanstack/react-query'

import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded'
import ModalEditOffering from './modalEditOffering'
import { OfferingResponseDTO } from '../../../apis/offering/dto'
import { QUERY_OFFERINGS_GET_BY_PLACE } from '../../../apis/moovyQueryKeys'
import ToysRoundedIcon from '@mui/icons-material/ToysRounded'
import { appRoutes } from '../../../app/permissions'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export interface OfferingsTabProps {
  placeId: string
  queryOfferings: UseQueryResult<OfferingResponseDTO[], any>
}

const OfferingsTab: React.FC<OfferingsTabProps> = ({
  placeId,
  queryOfferings
}) => {
  const navigate = useNavigate()
  const { isPermission } = useAuth()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [showOfferingModal, setShowOfferingModal] = useState<
    OfferingResponseDTO | undefined
  >(undefined)

  const offerings = queryOfferings.data || []

  const onClickRow = (item: OfferingResponseDTO) => {
    navigate(`${appRoutes.OFFERINGS}/${item.id}`)
  }

  const refreshData = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, placeId]
    })
  }

  const isWhitelist = (offering: OfferingResponseDTO) => {
    return offering.type === 'WHITELIST'
  }

  const isEditable = (offering: OfferingResponseDTO) => {
    return (
      isPermission('EDIT', 'OFFERING') &&
      !isWhitelist(offering) &&
      !offering.ignoreAmountForCatalogue
    )
  }

  return (
    <MoovyQueryHandler query={queryOfferings} rootPageIfNotFoundError>
      <MoovyContainer>
        <MoovyContainerSubHeader
          description={
            <FormattedMessage id="page.place.offeringsTab.infoText1" />
          }
        />
        <MoovyContainerContent>
          <MoovyGrid
            emptyTextId="page.place.offeringsTab.table.empty"
            errorTextId="page.place.offeringsTab.table.error"
            query={queryOfferings}
          >
            {offerings.map((offering) => (
              <MoovyGridCard
                key={offering.id}
                onCardClicked={() => onClickRow(offering)}
                cardTitle={offering.name ?? ''}
                cardSecondaryTitle={
                  <MoovyDateTime utcDateTime={offering.createdAt} dateOnly />
                }
                cardMenuActions={
                  isEditable(offering)
                    ? [
                        {
                          itemText: intl.formatMessage({
                            id: 'page.place.offeringsTab.table.offering.edit'
                          }),
                          onClick: () => setShowOfferingModal(offering)
                        }
                      ]
                    : undefined
                }
                cardContentList={[
                  <AllocatedRightsOffering offering={offering} />
                ]}
              />
            ))}
          </MoovyGrid>
          <MoovyTable
            items={offerings}
            emptyTextId="page.place.offeringsTab.table.empty"
            errorTextId="page.place.offeringsTab.table.error"
            query={queryOfferings}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="page.place.offeringsTab.table.product" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.place.offeringsTab.table.overview" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.place.offeringsTab.table.timestamps" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offerings.map((offering) => (
                <TableRow
                  key={offering.id}
                  onClick={() => onClickRow(offering)}
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {isWhitelist(offering) ? (
                        <ToysRoundedIcon style={{ marginRight: '1rem' }} />
                      ) : (
                        <LocalActivityRoundedIcon
                          style={{ marginRight: '1rem' }}
                        />
                      )}
                      {offering.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <AllocatedRightsOffering offering={offering} />
                  </TableCell>
                  <TableCell>
                    <TimeStampItems
                      createdAt={offering.createdAt}
                      updatedAt={offering.updatedAt}
                      dateOnly
                    />
                  </TableCell>
                  <TableCell align="right">
                    {isEditable(offering) && (
                      <MoovyLink
                        onClick={(evt) => {
                          evt.stopPropagation()
                          setShowOfferingModal(offering)
                        }}
                      >
                        <FormattedMessage id="page.place.offeringsTab.table.offering.edit" />
                      </MoovyLink>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MoovyTable>
          <ModalEditOffering
            open={!!showOfferingModal}
            onCancel={() => setShowOfferingModal(undefined)}
            onSubmit={() => {
              setShowOfferingModal(undefined)
              refreshData()
            }}
            offering={showOfferingModal}
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </MoovyQueryHandler>
  )
}

export default OfferingsTab
