import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_PLACE_GET_BY_REF
} from '../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyListLabelValue,
  MoovyRestricted
} from '../../../components'
import React, { useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ModalEditOffering from './modalEditOffering'
import { OfferingResponseDTO } from '../../../apis/offering/dto'
import { PlacesAPI } from '../../../apis/places/placesAPI'
import { appRoutes } from '../../../app/permissions'
import { getAmountAndPercentage } from '../../../utils/utils'
import useAuth from '../../../hooks/useAuth'

export type OfferingInfoProps = {
  queryOffering: UseQueryResult<OfferingResponseDTO, any>
}

const OfferingInfo = ({ queryOffering }: OfferingInfoProps) => {
  const [showModal, setShowModal] = useState(false)
  const intl = useIntl()
  const { tenant } = useAuth()

  const offering = queryOffering.data

  const queryPlace = useQuery({
    queryKey: [QUERY_PLACE_GET_BY_REF, offering?.placeId],
    queryFn: () => PlacesAPI.getPlaceByRef(offering?.placeId || ''),
    enabled: !!offering,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const place = queryPlace.data

  const lastItem = offering?.name || ''
  const customBreadcrumbs = {
    lastBreadcrumb: lastItem,
    customBreadcrumbs: !tenant
      ? [
          {
            route: `${appRoutes.PLACES}/${place?.id}`,
            name: place?.name || ''
          }
        ]
      : undefined
  }

  const isWhitelist = offering?.type === 'WHITELIST'
  const isEditable = !isWhitelist && !offering?.ignoreAmountForCatalogue

  const { name, summary, amount } = offering ?? {}
  const defaultValues = {
    offeringName: name || '',
    placeName: place?.name || '',
    amount: offering?.ignoreAmountForCatalogue
      ? intl.formatMessage({
          id: 'page.offering.offeringInfo.ignoreAmountLimits'
        })
      : amount,
    cataloguesAmount: isWhitelist
      ? summary?.catalogues
      : getAmountAndPercentage(
          summary?.cataloguesTotalAmount || 0,
          summary?.catalogues || 0,
          true
        )
  }

  const data = [
    {
      label: <FormattedMessage id="page.offering.offeringInfo.name" />,
      value: `${defaultValues.offeringName}`
    },
    {
      label: <FormattedMessage id="page.offering.offeringInfo.location" />,
      value: `${defaultValues?.placeName}`
    },
    {
      label: (
        <FormattedMessage id="page.offering.offeringInfo.catalogues.amount" />
      ),
      value: `${defaultValues.cataloguesAmount}`
    }
  ]
  if (!isWhitelist) {
    data.push({
      label: <FormattedMessage id="page.offering.offeringInfo.maximumAmount" />,
      value: `${defaultValues.amount}`
    })
  }

  return (
    <>
      <MoovyContainer>
        <MoovyContainerHeader breadcrumbs={customBreadcrumbs}>
          {name}
        </MoovyContainerHeader>
        <MoovyContainerSubHeader
          title={<FormattedMessage id="page.offering.offeringInfo.title" />}
          button={
            isEditable ? (
              <MoovyRestricted action="EDIT" resource="OFFERING">
                <MoovyButton
                  startIcon={<EditRoundedIcon />}
                  onClick={() => setShowModal(true)}
                  showAsIconButton
                  aria-label={intl.formatMessage({
                    id: 'page.offering.button.edit'
                  })}
                />
              </MoovyRestricted>
            ) : undefined
          }
          divider
        />
        <MoovyContainerContent>
          <MoovyListLabelValue data={data} />
        </MoovyContainerContent>
      </MoovyContainer>
      <ModalEditOffering
        open={showModal}
        offering={offering}
        onSubmit={() => {
          setShowModal(false)
        }}
        onCancel={() => setShowModal(false)}
      />
    </>
  )
}

export default OfferingInfo
