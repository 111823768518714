import { FormattedMessage } from 'react-intl'
import { ReactNode } from 'react'
import { styled } from '@mui/material'
import { OfferingResponseDTO } from '../../apis/offering/dto'

interface AllocatedRightItemProps {
  labelText: string | ReactNode
  value: string | ReactNode
  color?: 'RED' | 'GREEN'
}

export const AllocatedRightItem: React.FC<AllocatedRightItemProps> = ({
  labelText,
  value,
  color
}) => {
  const ColoredValue = styled('span')(
    ({ theme }) => `
    font-weight: bold;
    ${color === 'GREEN' ? `color: ${theme.palette.success.main};` : ''}
    ${color === 'RED' ? `color: ${theme.palette.error.main};` : ''}
  `
  )

  const Item = styled('span')`
    display: block;
    margin: 0;
  `
  const Value = styled('span')`
    font-weight: bold;
  `

  const StyledLabel = styled('span')`
    margin-right: 0.3rem;
  `

  return (
    <Item>
      <StyledLabel>{labelText}</StyledLabel>
      {color ? <ColoredValue>{value}</ColoredValue> : <Value>{value}</Value>}
    </Item>
  )
}

export const AllocatedRightsCatalogue: React.FC<{
  amount: number
  activeRights: number
}> = ({ amount, activeRights }) => {
  const availableRights = activeRights
  const percentage =
    availableRights > 0 && amount > 0
      ? Math.round((availableRights / amount) * 100)
      : 0

  return (
    <>
      <AllocatedRightItem
        labelText={<FormattedMessage id="allocatedRights.rightsGranted" />}
        value={amount}
      />
      <AllocatedRightItem
        labelText={<FormattedMessage id="allocatedRights.rightsShared" />}
        value={`${availableRights > 0 ? availableRights : 0} (${percentage} %)`}
        color={percentage > 100 ? 'RED' : undefined}
      />
    </>
  )
}

export const AllocatedRightsOffering: React.FC<{
  offering: OfferingResponseDTO
}> = ({ offering }) => {
  if (offering.type === 'WHITELIST') {
    return (
      <AllocatedRightItem
        labelText={
          <FormattedMessage id="page.place.offeringsTab.table.catalogue.amount" />
        }
        value={offering.summary.activeRights}
      />
    )
  }
  if (offering.ignoreAmountForCatalogue) {
    const { activeRights, cataloguesTotalAmount } = offering.summary
    const availableRights = cataloguesTotalAmount - activeRights
    const percentage =
      availableRights > 0 && cataloguesTotalAmount > 0
        ? Math.round((availableRights / cataloguesTotalAmount) * 100)
        : 0

    const valueText = `${activeRights} / ${cataloguesTotalAmount}  (${
      100 - percentage
    } %)`
    return (
      <AllocatedRightItem
        labelText={<FormattedMessage id="allocatedRights.rightsGranted" />}
        value={valueText}
      />
    )
  }

  const amount = offering.ignoreAmountForCatalogue
    ? offering.summary.cataloguesTotalAmount
    : offering.amount
  const activeRights = offering.ignoreAmountForCatalogue
    ? offering.summary.activeRights
    : offering.summary.cataloguesTotalAmount

  const availableRights = amount - activeRights
  const percentage =
    availableRights > 0 && amount > 0
      ? Math.round((availableRights / amount) * 100)
      : 0

  const greenText = percentage && percentage <= 100
  const redText = availableRights <= 0
  const availableText = `${
    availableRights > 0 ? availableRights : 0
  } (${percentage} %)`

  return (
    <>
      <AllocatedRightItem
        labelText={<FormattedMessage id="allocatedRights.rightsTotal" />}
        value={amount}
      />
      <AllocatedRightItem
        labelText={<FormattedMessage id="allocatedRights.available" />}
        value={availableText}
        color={greenText || redText ? (greenText ? 'GREEN' : 'RED') : undefined}
      />
    </>
  )
}
