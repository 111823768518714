import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { Formik } from 'formik'
import { MoovyForm } from '../../../../components'
import { isValidLicensePlate } from '../../../../utils/validator'

export interface DefaultRightHolderValues {
  licensePlate: string
  costCenter?: string
  project?: string
}

type SubmitFormCallback = (values: DefaultRightHolderValues) => void

export type FormIndividualRightHolderProps = {
  formReference: string
  onSubmit: SubmitFormCallback
  showAccountingFields?: boolean
}

const initialValues = {
  licensePlate: '',
  costCenter: '',
  project: ''
}

const FormIndividualRightHolder = (props: FormIndividualRightHolderProps) => {
  const intl = useIntl()
  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)

  const validationSchema = yup.object().shape({
    licensePlate: yup
      .string()
      .trim()
      .test(
        'licensePlate',
        intl.formatMessage({
          id: 'common.form.errors.licensePlate'
        }),
        function (licensePlate?: string) {
          return isValidLicensePlate(licensePlate)
        }
      ),
    costCenter: yup.string(),
    project: yup.string()
  })

  const onSubmitFormValues = (values: DefaultRightHolderValues) => {
    const { costCenter, project } = values
    props.onSubmit({
      licensePlate: values.licensePlate.trim().toLocaleUpperCase(),
      costCenter,
      project
    })
  }

  return (
    <Formik
      validate={() => setFormikValidateOnChange(true)}
      validationSchema={validationSchema}
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={formikValidateOnChange}
      onSubmit={(values) => {
        onSubmitFormValues(values)
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit} id={props.formReference}>
          <MoovyForm.FormContainer>
            <MoovyForm.FormTitle
              title={
                <FormattedMessage id="page.catalogues.tabCatalogue.formVehicleRightHolder.label.vehicleDetails" />
              }
            />
            <MoovyForm.FormTextField
              fullWidth
              name="licensePlate"
              data-testid="licensePlate"
              value={values.licensePlate}
              label={intl.formatMessage({
                id: 'common.text.licensePlate'
              })}
              placeholder={intl.formatMessage({
                id: 'common.text.licensePlate'
              })}
              onChange={handleChange}
              autoFocus
              error={!!errors.licensePlate}
              errorText={errors.licensePlate}
            />
            {props.showAccountingFields && (
              <>
                <MoovyForm.FormTextField
                  name="costCenter"
                  data-testid="costCenter"
                  value={values.costCenter}
                  onChange={handleChange}
                  label={intl.formatMessage({
                    id: 'common.text.costCenter'
                  })}
                  error={!!errors.costCenter}
                  errorText={errors.costCenter}
                />
                <MoovyForm.FormTextField
                  name="project"
                  data-testid="project"
                  value={values.project}
                  onChange={handleChange}
                  label={intl.formatMessage({
                    id: 'common.text.project'
                  })}
                  error={!!errors.project}
                  errorText={errors.project}
                />
              </>
            )}
          </MoovyForm.FormContainer>
        </form>
      )}
    </Formik>
  )
}

export default FormIndividualRightHolder
